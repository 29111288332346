$medGreen: #0b740b;
$charcoal: #30363a;
$delete: #ee5342;

$test: #0E4787;
// Pallete
$primary: #228da8;
$secondary1: #1AA3CE;
$secondary: #2A93D1;
$tertiary: #0066F4;
$wave: #0C3F75;
$turquoiseBlue: #80ffe8;
$turquoiseBlueDarker: #50d4bc;
$violet: #201E50;
$offWhite: #fcfffc;
$gray: #516f90;
$lightGray: #dadfe4;
$orange: #FD5C29;