@import "../../../Constants/Colors.scss";
@import "../../../Constants/Dimensions.scss";

.sectionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;
}

.sectionWrapper:last-child {
    border-bottom: 2px solid #C1BEB5;
}

    .sectionHeader {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-size: 24px;
        font-weight: 600;
        color: #000;
        user-select: text;
        box-sizing: border-box;
        cursor: pointer;
        cursor: pointer;
            label {
            text-align: left;
            width: 100%;
            cursor: pointer;
            }
    }
    
    .preview {
    overflow: hidden;
    position: relative;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 23rem;
    line-height: 1.5;
    font-weight: 500;
    box-sizing: border-box;
    font-size: 1rem;
    border: none !important;
    }

    .description {
        line-height: 1.5;
        padding: 0 10px;
        font-size: 1rem;
        font-weight: 500;
    }


.readMore {
    line-height: 1.5;
    font-size: 0.9rem;
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
    width: 35%;
    color: lighten($wave, 10%);
    }
    
    .readMore:hover {
    color: lighten($wave, 20%)
    }
    
    .readLess {
    font-size: 0.9rem;
    width: fit-content;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
    color: lighten($wave, 10%);
    transition: all 400ms ease;
    }
    
    .readLess:hover {
    color: lighten($wave, 20%);
    }
    

.addIcon {
    cursor: pointer;
    margin-right: 0.5rem;
    color: #000;
    box-sizing: border-box;
}

@media only screen and (max-width: $smallDisplay) {
    .readMore {
        font-size: 1rem;
    }
    .readLess {
        font-size: 1rem;
    }
    .preview {
        width: 40rem !important;
    }
    .description {
        padding: 0 10px;
        font-size: 1rem !important;
        width: 40rem !important;
    }

}

@media only screen and (max-width: $mobileDisplay) {
    .preview {
        width: 25rem !important;
    }
    .description {
        width: 25rem !important;
    }
}

@media only screen and (max-width: $smallMobileDisplay) {
    .preview {
        width: 15rem !important;
    }
    .description {
        width: 15rem !important;
    }
}


@media only screen and (max-width: 425px) {
    .preview {
        width: 14rem !important;
        font-size: 0.9rem !important;
    }
    .description {
        width: 14rem !important;
        font-size: 0.9rem !important;
    }
    .readMore {
        font-size: 0.8rem;
    }
    .readLess {
        font-size: 0.8rem;
    }
}

@media only screen and (max-width: 340px) {
    .preview {
        width: 10.5rem !important;
        font-size: 0.8rem !important;
    }
    .description {
        width: 10.5rem !important;
        font-size: 0.8rem !important;
    }
    .readMore {
        font-size: 0.7rem;
    }
    .readLess {
        font-size: 0.7rem;
    }
}
