@import "../../Constants/Colors.scss";
@import "../../Constants/Dimensions.scss";

.wrapper {
  z-index: 999;
  position: fixed;
  width: 100%;
  top: 0;
  
  
  box-sizing: border-box;
  padding: 10px 15px;
  padding-left: 100px;
  user-select: text;
  h1 {
    font-size: 25px;
  }
}

.wrapperPrimary {
  background-color: #fcfffcc4;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

.headerPageSpacer {
  width: 100%;
  height: 220px;
}

.navSection {
  display: flex;
}

@media screen and (max-width: 1200px) {
  .headerPageSpacer {
    width: 100%;
    height: 110px;
  }
}

@media screen and (max-width: $tablet) {
  .wrapper {
    padding-left: 40px !important;
  }
  .navSection {
    display: none !important;
  }
}
