
.mediaIcons {
    z-index: 99;
    padding: 15px;
    position: fixed;
    bottom: 60px;
    left: 15px;
    background-color: #fff;
    border-radius: 15px;
    background-color: #fcfffcc4;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    overflow: hidden;
    .icon {
    margin-right: 12px;
    transition: ease-in-out 300ms;
    cursor: pointer;
    }
    .icon:last-child {
    margin-right: 0;
    }
    .icon:hover {
        transform: translateY(-3px);
    }
}