@import "../../../Constants/Dimensions.scss";
@import "../../../Constants/Colors.scss";

.hero_wrapper {
    position: relative;
    background: $wave;
    flex: 1;
    overflow: hidden;
}

.particle_container {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
}

.angle {
    transform: rotate(-5deg);
    position: absolute;
    height: 60%;
    bottom: -40%;
    left: -20%;
    width: 140%;
    overflow: hidden;
    z-index: 1;
    background: #0B3F75;
    box-shadow: 0px 0px 100px -5px rgba(0, 0, 0, 0.637);
}

.box {
    position: absolute;
    background-color: $secondary;
    opacity: .15;
    box-shadow: rgb(0 0 0 / 50%) 0px 0px 30px -5px;
    height: 30%;
    left: 0 !important;
    bottom: 0;
    width: 10%;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
}

.image_bg {
    position: absolute;
    background-color: $secondary;
    opacity: .15;
    box-shadow: rgb(0 0 0 / 50%) 0px 0px 30px -5px;
    top: 15%;
    height: 65%;
    right: 0 !important;
    width: calc(70% - 425px);
    border-top-left-radius: 500px;
    border-bottom-left-radius: 175px;
    border-top-right-radius: 170px;
    border-bottom-right-radius: 550px;
}

.wave_img {
    position: absolute;
    bottom: 0 !important;
    opacity: .3;
}

.wave_img_animate {
    position: absolute;
    bottom: 0 !important;
    opacity: .75;
    animation: wave_animate 10s ease-in infinite;
    animation-direction: alternate;
}

@keyframes wave_animate {
    0% {
        left: 0%
    }
    100% {
        left: -100%
    }
}

@media only screen and (max-width: 1350px) {
    .image_bg {
        display: none;
    }
}

@media only screen and (max-width: $mobileDisplay) {
    .angle {
        visibility: hidden;
    }
}
