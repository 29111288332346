@import "../../../Constants/Colors.scss";
@import "../../../Constants/Dimensions.scss";
@import "../../../Constants/DefaultFonts.scss";

.content {
    color: #fff;
    font-size: 32px;
    font-weight: 500;
    font-style: italic;
    font-family: $brand;
    letter-spacing: 0.03051em;
}

.highlight {
    height: 2px;
    width: 100px;
    margin: 15px 0;
}

@media only screen and (max-width: $smallMobileDisplay) {
    .content {
        font-size: 24px !important;
    }
    .highlight {
        height: 2px;
        width: 70px;
    }
}

@media only screen and (max-width: 510px) {
    .content {
        font-size: 18px !important;
    }
    .highlight {
        height: 2px;
        width: 50px;
        margin: 8px 0;
    }
}

@media only screen and (max-width: 400px) {
    .highlight {
        height: 2px;
        width: 40px;
    }
    .content {
        white-space: nowrap;
    }
}

@media only screen and (max-width: 300px) {
    .content {
        display: none !important;
    }
    .highlight {
        display: none !important;
    }
}
