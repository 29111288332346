@import "../../Constants/Dimensions.scss";

.commonBlock {
  width: 30vw;
  height: 30vw;
  max-height: 70vh;
  max-width: 70vh;
  border-radius: 25px;
}

@media screen and (max-width: $smallTablet) {
  .commonBlock {
    width: 50vw;
    height: 50vw;
    max-height: 60vh;
    max-width: 60vh;
    border-radius: 15px;
  }
}

@media screen and (max-width: $mobileDisplay) {
  .commonBlock {
    width: 40vw;
    height: 40vw;
    max-height: 70vh;
    max-width: 70vh;
    border-radius: 25px;
  }
}


@media screen and (max-width: $smallMobileDisplay) {
  .commonBlock {
    width: 60vw;
    height: 60vw;
    max-height: 70vh;
    max-width: 70vh;
    border-radius: 25px;
  }
}

@media screen and (max-width: $tinyMobile) {
  .commonBlock {
    width: 60vw;
    height: 60vw;
    max-height: 60vh;
    max-width: 60vh;
    border-radius: 25px;
  }
}