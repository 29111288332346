@import "../../../Constants/Dimensions.scss";
@import "../../../Constants/Colors.scss";

.wrapper {
    width: 100%;
    padding: $regPadding;
    user-select: text;
    overflow: auto;
    margin-bottom: 200px;
    user-select: none !important;
}

.contentWrapper {
    min-height: 55vh;
    margin-top: 50px;
}

.expansionWrapper {
    flex: 0 0 auto;
    width: 55%;
    p {
        line-height: 1.5;
    }
}

.grabber_content {
    font-size: 28px;
    text-align: center;
    margin-bottom: 10px;
}

@media only screen and (max-width: $smallDisplay) {
    .wrapper {
        margin-bottom: 100px;
    }
    .expansionWrapper {
        width: 50%;
        p {
            line-height: 1.5;
        }
    }
    .grabber_content {
        font-size: 22px;
    }
}

@media only screen and (max-width: 1125px) {
    .wrapper {
        padding: $minPadding;
        margin-bottom: 10px;
    }
    .contentWrapper {
        min-height: 40vh !important;
    }
}

@media only screen and (max-width: $smallTablet) {
    .contentWrapper {
        flex-direction: column !important;
    }
    .expansionWrapper {
        width: 100%;
        padding: 20px;
    }
}

@media only screen and (max-width: $mobileDisplay) {
    .wrapper {
        h1 {
            font-size: 30px !important;
            text-align: center;
        }
    }
    .grabber_content {
        font-size: 18px;
    }
}

@media only screen and (max-width: $smallMobileDisplay) {
    .wrapper {
        padding: 30px;
    }
}
