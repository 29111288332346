@import "../../../Constants/Dimensions.scss";

.wrapper {
  width: 100%;
  background: white;
}

.content {
  width: 100%;
  box-sizing: border-box;
  padding: $minPadding;
  padding-top: 0px;
  min-height: 70vh;
  color: black;

  h2 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 35px;
    font-size: 24px;
  }

  .lottieWrapper {
    flex: 1;
  }
}

@media screen and (max-width: $tablet) {
  .content {
    flex-direction: column !important;
  }
}

@media screen and (max-width: $mobileDisplay) {
  .content {
    padding: $tabletPadding !important;
  }
}