@import "../../../Constants/Dimensions.scss";
@import "../../../Constants/Colors.scss";
@import "../../../Constants/DefaultFonts.scss";

.button_submit {
    padding: 3px 20px;
    border-radius: 3px;
    margin-top: 1rem;
    color: #fff !important;
}

@media only screen and (max-width: $smallMobileDisplay) {
    .button_submit {
        width: 100% !important;
        margin-top: 1rem;
    }
    .header {
        font-size: 24px !important;
    }
}
