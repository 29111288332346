@import "../../Constants/Colors.scss";
@import "../../Constants/Dimensions.scss";

.wrapper {
    width: 100%;
}

@media only screen and (max-width: $smallDisplay) {
    .parallax_wrapper {
    height: 65vh !important;
    }
    .banner_content {
        width: 100% !important;
    }
}

@media only screen and (max-width: 950px) {
    .parallax_wrapper {
    height: 45vh !important;
    }
}

@media only screen and (max-width: 800px) {
    .parallax_wrapper {
    height: 35vh !important;
    }
    .retirement_content {
        flex-direction: column !important;
    }
    .bullet_points {
        width: 80%;
    }
}

@media only screen and (max-width: 650px) {
    .parallax_wrapper {
    height: 30vh !important;
    }
}

@media only screen and (max-width: 435px) {
    .parallax_wrapper {
    height: 25vh !important;
    }
}

@media only screen and (max-width: 375px) {
    .parallax_wrapper {
    height: 20vh !important;
    }
}