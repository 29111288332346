
.invalidInfoOverlay {
    background-color: rgba(255, 255, 255, 0.803);
    -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 0;
    bottom: 0;
    position: fixed;
    justify-content: center;
    align-items: center;
}