@import "../../../Constants/Dimensions.scss";
@import "../../../Constants/Colors.scss";

.wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}


.video_section {
  position: absolute;
  inset: 0px;
  overflow: hidden;
  background-size: cover;
  background-color: transparent;
  background-repeat: no-repeat;
  background-image: none;
}

.video {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
}

.video_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $charcoal;
  z-index: 3;
  opacity: 0.5;
}

.landing_mobile_wrapper {
  display: flex;
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  flex: 1 !important;
  width: 100% !important;
  height: 100% !important;

  img {
    background-repeat: no-repeat;
    background-size: cover;
    height: auto !important;
    width: 100vw !important;
    object-fit: cover !important;
    bottom: 0;
    left: 0;
  }
}

.hero_grabber_section {
  position: relative;
  width: 90%;
  padding: $minPadding;
  user-select: text;
  z-index: 4;

  .headline {
    color: #fff;
    font-size: 48px !important;
    font-weight: 500;
    line-height: 1.5;
    // margin-bottom: 0;
  }

  .quote {
    opacity: 0.4;
    color: #fff;
    margin-bottom: 1rem;
  }

  .leftQuote {
    transform: scaleX(-1);
  }

  h1 {
    white-space: nowrap;
    color: #fff;
    margin: 0;
  }
}

.signatureHighlight {
  background-color: $secondary1;
  height: 0.35rem;
  width: 1.5rem;
}

.signature {
  color: #fff;
  font-size: 26px;
  margin-left: 0.5rem;
  font-style: italic;
  font-weight: 600;
  white-space: nowrap;
}

.landing_call_to_action {
  margin-top: 1rem;
}

.home_hero_scroll_text {
  position: absolute;
  bottom: 2.8rem;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .12rem;
  font-size: 16px;
  font-weight: 700;
  animation: scrolldown 3s infinite;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  opacity: 0;
  user-select: text;
  z-index: 5 !important;
}

@keyframes scrolldown {
  0% {
    transform: translate(-50%, 0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translate(-50%, 20px);
    opacity: 0;
  }
}

.video_flex_wrapper {
  position: relative;
  padding-top: 56.25%; // 16:9 Aspect Ratio (divide 9 by 16 = 0.5625)
  width: 100%;
  height: 100%;
  z-index: 3 !important;
  // background-color: $charcoal;
}

.logo {
  z-index: 1;
}


@media screen and (max-width: $tablet) {

  .hero_grabber_section {
    .headline {
      color: #fff;
      font-size: 32px !important;
      font-weight: 500;
    }
  }
}


@media screen and (max-width: 1100px) {
  .wrapper {
    height: 50vh !important;
  }

  .landing_call_to_action {
    border-radius: 8px !important;
  }
}

@media screen and (max-width: $smallTablet) {
  .hero_grabber_section {
    width: 100%;
  }

  .wrapper {
    display: none;
  }

  .landing_mobile_wrapper {
    height: 100%;

    img {
      height: 100% !important;
    }
  }
}


@media screen and (max-width: $mobileDisplay) {

  .landing_call_to_action {
    border-radius: 6px !important;
    border-width: 2px !important;
  }

  .landing_call_to_action {
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: $smallMobileDisplay) {
  .hero_grabber_section {
    padding: $mobilePadding;
  }

  .wrapper {
    display: none;
  }

  .landing_mobile_wrapper {
    height: 100%;

    img {
      height: 100% !important;
    }
  }
}

@media screen and (max-width: 550px) {

  .hero_grabber_section {
    .headline {
      color: #fff;
      font-size: 24px !important;
      line-height: 1;
    }
  }

  .quote {
    font-size: 16px !important;
    margin-bottom: 0.5rem !important;
  }

  .hero_grabber_section {
    p {
      font-size: 12px !important;
    }
  }

  .home_hero_scroll_text {
    font-size: 10px !important;
  }
}

@media screen and (max-width: $tinyMobile) {
  .hero_grabber_section {
    h1 {
      font-size: 24px !important;
    }

    .headline {
      font-size: 16px !important;
      line-height: 1;
    }
  }

  .quote {
    font-size: 12px !important;
    margin-bottom: 0.25rem !important;
  }


  .home_hero_scroll_text {
    bottom: 2rem !important;
  }
}

@media screen and (max-width: 374px) {
  .wrapper {
    height: 23vh !important;
  }

  .hero_grabber_section {
    p {
      font-size: 10px !important;
    }

    h1 {
      font-size: 16px !important;
    }
  }
}