@import "../../../Constants/Colors.scss";
@import "../../../Constants/Dimensions.scss";

.section {
    color: #fff;
    text-align: center;
}

.content {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.03em;
}

@media only screen and (max-width: 800px) {
    .section {
        h2 {
            font-size: 24px;
        }
    }
    .content {
        font-size: 20px;
    }
}

@media only screen and (max-width: 650px) {
    .content {
        display: none;
    }
}

@media only screen and (max-width: 400px) {
    .section {
        h2 {
            font-size: 20px;
        }
    }
}