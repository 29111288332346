@import "../../../Constants/Dimensions.scss";
@import "../../../Constants/Colors.scss";


.wrapper {
    width: 425px;
    border: 1px solid #dde5e5;
    margin-bottom: 40px;
    border-radius: 20;
    overflow: hidden;
    margin-right: 10px;
}

.banner {
    padding: 1rem;
    height: 12rem;
    min-height: 12rem;
    h3 {
        color: #fff;
        font-size: 1.4rem;
        line-height: 1.35;
        margin-bottom: 0;
    }

    span {
        padding: 10px 0;
        color: #fff;
    }
}

.featured_section {
    padding: 1rem;
    min-height: 16rem;
    background-color: $offWhite;
    h4 {
        margin: 10px 0;
    }
}

.bio_card {
    max-width: 150px;
    max-height: 150px;
    min-height: 50px;
}

.registerButton {
color: $orange;
font-weight: 600;
font-size: 16px;
padding-left: 1rem;
}

.buttonIcon {
    color: $orange;
}

@media only screen and (max-width: $smallTablet) {
    .banner {
        h3 {
            line-height: 1.25;
        }
    }    
}

@media only screen and (max-width: 550px) {
    .wrapper {
        width: 400px;
        margin-bottom: 40px;
        margin-right: 5px;
    }
    .banner {
        padding: 0.8rem;
        height: 9rem;
        min-height: 9rem;
        h3 {
            font-size: 1.2rem;
            line-height: 1;
        }
    }    
}

@media only screen and (max-width: 400px) {
    .wrapper {
        width: 350px;
    }
    .banner {
        padding: 0.8rem;
        height: 9rem;
        min-height: 9rem;
        h3 {
            font-size: 1.1rem;
            line-height: 1;
        }
    }    
    .featured_section {
        min-height: 14rem;
    }
}

@media only screen and (max-width: 350px) {
    .wrapper {
        width: 250px;
    }
    .banner {
        padding: 0.7rem;
        height: 8rem;
        min-height: 8rem;
        h3 {
            font-size: 1rem;
        }
        .date_wrapper {
            font-size: 14px !important;
        }
        .event_time {
            font-size: 14px !important;
        }
    }    
    .featured_section {
        padding: 0.6rem;
    }
}