@import "../../../Constants/Colors.scss";

.wrapper {
    margin: 5px;
    // flex: 1;
}

.image {
    padding: 10px;
}

.details {
    color: $violet;
    font-size: 12px;
    font-weight: 500;

    .name {
        font-size: 16px;
    }
    .title {
        font-size: 11px;
    }
    span {
        margin: 3px 0;
    }
}