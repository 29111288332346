@import "../../Constants/Dimensions.scss";
@import "../../Constants/Colors.scss";

    .wrapper {
    position: relative;
    height: 100vh;
    width: 100%;
    }


    .parallax_section {
    position: absolute;
    inset: 0px;
    overflow: hidden;
    background-size: cover;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: none;
    }
    
    .parallax {
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: visible;
    width: 120vw;
    height: 100vh;
    padding: 0;
    margin: 0;
    opacity: 0.5;
    z-index: 1;
    img {
        object-fit: cover;
        height: 100%;
    }
    }

    .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $charcoal;
    }

.content {
    position: relative;
    width: 100%;
    padding: $minPadding;
    user-select: text;
    z-index: 9;
}

@media only screen and (max-width: $smallDisplay) {
    .parallax {
        display: flex !important;
        background-position: 50% -80px !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
    }
    .wrapper {
        height: 30vh;
    }
}

@media only screen and (max-width: 650px) {
    .wrapper {
        height: 25vh;
    }
}

@media only screen and (max-width: 300px) {
    .parallax {
        display: none !important;
    }
    .wrapper {
        display: none !important;
    }
}
