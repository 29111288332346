@import "../../../Constants/Dimensions.scss";
@import "../../../Constants/DefaultFonts.scss";
@import "../../../Constants/colors.scss";

.wrapper {
    width: 100vw;
    overflow: hidden;
}

.markdownListWrapper {
    p {
        font-size: 22px;
        line-height: 1.5;
        font-weight: 500;
        strong {
            color: $primary;
        }
    }
    h1,h2,h3,h4 {
        margin: 0 !important;
        margin-top: 20px !important;
    }

    strong {
        color: $primary;
    }
}

.whiteContent {
    p {
        font-size: 22px;
        line-height: 1.5;
        font-weight: 500;
        color: white;
        strong {
            color: $primary;
        }
    }
    h1,h2,h3,h4 {
        color: white;
        margin: 0 !important;
        margin-top: 20px !important;
    }

    strong {
        color: $primary;
    }
}

.header {
    width: 100%;
    padding: $regPadding;

    h2 {
        padding: 0 !important;
        margin-bottom: 0.5rem !important;
        font-size: 32px;
    }

    .header_highlight {
        width: 12rem;
        display: flex;
        align-self: center;
        height: 0.2rem;
        // background: $wave;
        margin: 0 !important;
    }
}

.angle {
    transform: rotate(-3deg);
    position: absolute;
    height: 100%;
    bottom: -40%;
    left: -20%;
    width: 140%;
    overflow: hidden;
    z-index: 1;
    background: #0B3F75;
}