@import "../../../Constants/Dimensions.scss";
@import "../../../Constants/Colors.scss";

.wrapper {
    width: 100%;
    user-select: text;
    padding: $minPadding;
}

.webinar_cell:nth-child(1n) {
    background-color: $orange !important;
}

.webinar_cell:nth-child(1n+2) {
    background-color: $primary !important;
}

.webinar_cell:nth-child(3n) {
    background-color: $charcoal !important;
}


.webinar_cell:nth-child(3n+4) {
    background-color: $orange !important;
}

@media only screen and (max-width: $smallTablet) {
    .wrapper {
        padding: $mobilePadding !important;
    }
}
