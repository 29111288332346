@import "../../Constants/Colors.scss";
@import "../../Constants/Dimensions.scss";

.wrapper {
    width: 100%;
    flex: 1;
    padding: $minPadding;
    // text-align: center;

    h2 {
        color: $violet;
        font-weight: 400;
        text-align: center;
    }
}

.children {
    width: 100%;
    padding: 25px 0;
}

.image {
    margin: 10px;
}

.image_section {
    margin-right: 10px;
}

@media only screen and (max-width: 800px) {
    .wrapper {
        padding: $mobilePadding;
    }
}