$minPadding: 30px 80px;
$regPadding: 30px 150px;
$tabletPadding: 10px 40px;
$mobilePadding: 20px;


$minPageHeight: calc(100vh - 60px);

$extraLargeDisplay: 2000px;
$regularDisplay: 1550px;
$smallDisplay: 1399px;
$tablet: 1200px;
$smallTablet: 1000px;
$mobileDisplay: 875px;
$smallMobileDisplay: 650px;
$tinyMobile: 430px;