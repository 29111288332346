@import "../../Constants/Colors.scss";
@import "../../Constants/DefaultFonts.scss";
@import "../../Constants/Dimensions.scss";

.logoText {
  // color: #000;
  font-family: $brand;
  white-space: nowrap;
}

.logoStrong {
  // color: #000;
  font-family: $brand;
  // padding-bottom: 6px;
}

.selected {
  transform: translateY(-2px) !important;
  // border-bottom: 4px solid $secondary !important;
}

.tagline {
  // color: #000;
  font-family: $brand;
  font-style: italic;
}

@media screen and (max-width: $regularDisplay) {
  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
    margin: 0;
    padding: 0;
  }

  h5 {
    font-size: 16px;
    margin: 0;
    padding: 0;
  }

  h6 {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  p {
    font-size: 17px !important;
    line-height: 1.8rem;
  }
}

@media screen and (max-width: 570px) {
  .logoText {
    display: none;
  }
}