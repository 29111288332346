

.wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
}

.scrollSection {
    position: relative !important;
}

