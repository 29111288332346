@import "../../../Constants/Colors.scss";
@import "../../../Constants/DefaultFonts.scss";

.wrapper {
  background: rgba(0, 0, 0, 0);
  transition: 200ms;
  position: relative;
  padding: 10px;
  margin: 0px 4px;
  user-select: text;
  span {
    font-weight: 600;
    font-size: 18px;
    user-select: none;
  }
}

.primary {
  cursor: pointer;
  transition: 200ms;
  white-space: nowrap;
  border-bottom: 4px solid rgba(0,0,0,0);
  padding-bottom: 5px;
  box-sizing: border-box;
}

.isButton {
  top: 55px !important;
}

.primary:hover {
  transform: translateY(-2px);
  border-bottom: 4px solid $secondary;
}

.primaryActive {
  transform: translateY(-2px) !important;
  border-bottom: 4px solid $secondary !important;
}

.alternate {
  color: black;
}

.alternate:hover {
  color: white;
  // background: rgba(0, 162, 255, 0.555);
}

.subLink {
  border-radius: 10px;
  align-content: center;
  justify-content: flex-start;
  white-space: nowrap;
  span {
    display: flex;
    align-items: center;
    color: $charcoal;
    padding: 0 6px;
  }
}

.subLink:hover {
  // background: rgba(0, 144, 196, 0.13);
  background: rgba(205, 242, 252, 100);
}
