@import "./Constants/Dimensions.scss";

html {
  font-size: 20px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent !important;
  scroll-behavior: smooth !important;
  // *FireFox*
  scrollbar-width: none !important;
  /*IE10+*/
  -ms-overflow-style: -ms-autohiding-scrollbar !important;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button {
  outline: none;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  font-size: 20px;
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 25px;
}

h4 {
  font-size: 20px;
  margin: 0;
  padding: 0;
}

ul {
  text-decoration: none;
  list-style: none;
  padding: 0;
}

p {
  font-size: 18px;
  line-height: 2.4rem;
}

a {
  text-decoration: none;
  color: #516f90;
}

.MuiBackdrop-root {
  background-color: rgba(254, 254, 254, 0.619) !important;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

::-webkit-scrollbar {
  /*FireFox*/
  scrollbar-width: none;
  /*IE10+*/
  -ms-overflow-style: -ms-autohiding-scrollbar !important;
  -webkit-appearance: none !important;
  width: 0 !important;
  height: 0 !important;
  /*Chrome, Safari, Edge*/
  display: none !important;
}

@media screen and (max-width: $regularDisplay) {
  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
    margin: 0;
    padding: 0;
  }
  p {
    font-size: 17px !important;
    line-height: 1.8rem;
  }
}

@media screen and (max-width: $smallDisplay) {
  h1 {
    font-size: 45px;
  }

  h2 {
    font-size: 25px;
  }

  h3 {
    font-size: 17px;
  }

  h4 {
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
  p {
    font-size: 15px !important;
    line-height: 1.5rem;
  }
}

sup {
  font-size: 12px;
  font-weight: 700;
}

@media screen and (max-width: $tablet) {
  h1 {
    font-size: 40px !important;
  }
}

@media screen and (max-width: $smallMobileDisplay) {
  h1 {
    font-size: 30px !important;
  }
}
