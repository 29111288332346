.ecm2ProcessHighlight {
    display: flex;
    justify-content: flex-start !important;
    background-color: #38A8DA;
    border-radius: 10px;
    color: #fff;
    margin-top: 12px;
    top: -15px;
    left: 10px;
    overflow: visible;
    position: relative;
    max-width: 600px;
    font-weight: 22px;

    .ecm2ProcessText {
        text-align: left;
        line-height: 1.5;
        padding: 16px;
    }
}

.boldVariant {
    font-weight: 700;
    font-size: 24px;
}

@media only screen and (max-width: 1000px) {
    .ecm2ProcessHighlight {
        // padding: 0 16px;
        padding-left: 16px;
        padding-right: 6px;
        text-align: left;

        .ecm2ProcessText {
            line-height: 1.5;
            padding: 16px 2px !important;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .boldVariant {
        font-size: 20px;
    }
}