@import "../../Constants/Colors.scss";
@import "../../Constants/Dimensions.scss";

.bullet_item_wrapper {
  margin: 10px 0 5px 0;
  p {
    font-size: 22px;
    line-height: 1.5;
    font-weight: 500;
    strong {
        color: $turquoiseBlue !important;
    }
  }
  h1,h2,h3,h4 {
      margin: 0 !important;
      margin-top: 20px !important;
  }

  strong {
      color: $turquoiseBlue !important;
  }
 
  .bullet_item {
      font-size: 20px;
      font-weight: 600;
      P {
          margin: 0 !important;
      }
  }
  .check_icon {
      max-width: 1.5rem !important;
      max-height: 1.5rem !important;
  }
}

@media only screen and (max-width: $tablet) {
  .bullet_item {
      font-size: 18px !important;
      font-weight: 600;
  }
}