@import "../../Constants/Dimensions.scss";

.contentSection {
  margin: 100px 0px;
  width: 100%;
  padding: $regPadding;
  // padding-top: 100px;
  // padding-bottom: 100px;
  user-select: text;

  p {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.5;
  }

  h2 {
    margin-top: 10px;
    padding-top: 0;
  }
}

.extraContent {
  padding: $regPadding;
}

.contentReverse {
  flex-direction: row-reverse !important;

  div:nth-child(1) {
    justify-content: flex-end;
  }
}

.headerHighlight {
  margin: 0;
  padding: 0;
}

.whiteButton {
  color: #fff;
}

.whiteIcon {
  color: #fff;
}

.textGroup {
  flex: 1;
  box-sizing: border-box;
  // padding-top: 1%;
}

@media screen and (max-width: $tablet) {

  .contentSection {
    padding: $minPadding;
  }

  .extraContent {
    padding: $minPadding;
  }

  .textGroup {
    padding-top: 0px;
  }
}

@media screen and (max-width: $smallTablet) {

  .contentSection {
    padding: $tabletPadding;
    flex-direction: column !important;
  }

  .extraContent {
    padding: $tabletPadding;
  }

  .textGroup {
    margin-top: 40px;
  }

  .copySection {
    margin-top: 40px;
  }
}

// @media screen and (max-width: $smallMobileDisplay) {

//   .contentSection {
//     padding: $mobilePadding;
//   }

// }