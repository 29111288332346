@import "../../Constants/Dimensions.scss";

.container {
  z-index: 999;
  font-size: 6px;
}

.navIcon4 {
    display: none;
    align-self: center;
    width: 5em;
    height: 3em;
    position: relative;
    margin: 0 2em;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 200ms ease-in-out;
    -moz-transition: 200ms ease-in-out;
    -o-transition: 200ms ease-in-out;
    transition: 200ms ease-in-out;
    cursor: pointer;
  }
  
  .navIcon4 span {
    display: block;
    position: absolute;
    height: 0.6em;
    width: 100%;
    background: #000000;
    border-radius: 0.9em;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 200ms ease-in-out;
    -moz-transition: 200ms ease-in-out;
    -o-transition: 200ms ease-in-out;
    transition: 200ms ease-in-out;
  }
  
  .navIcon4 span:nth-child(1) {
    top: 0;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  
  .navIcon4 span:nth-child(2) {
    top: 1.2em;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  
  .navIcon4 span:nth-child(3) {
    top: 2.4em;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  
  .navIcon4.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -0.3em;
    left: 0.8em;
  }
  
  .navIcon4.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }
  
  .navIcon4.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 3.3em;
    left: 0.8em;
  }
  
  .hamburger {
    width: 3.5em;
    height: 0.5em;
    border-radius: 0.5em;
    background-color: black;
    margin: 0.9em 0;
  }
  
  .top-nav-hamburger {
    margin-left: 2em;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 200ms ease-in-out;
    -moz-transition: 200ms ease-in-out;
    -o-transition: 200ms ease-in-out;
    transition: 200ms ease-in-out;
    cursor: pointer;
  }

  @media screen and (max-width: $tablet) {
      .navIcon4 {
          display: block !important;
      }
  }