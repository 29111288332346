@import "../../Constants/Dimensions.scss";
@import "../../Constants/Colors.scss";

.wrapper {
  position: relative;
  width: 100%;
  height: 80vh;
}

.video_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $charcoal;
  z-index: 3;
  opacity: 0.5;
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 0px;
  padding-top: 0px;
  margin-top: -80px;
  z-index: 1;

  h1 {
    font-size: 60px;
  }

  h3 {
    font-size: 25px;
  }
}

.headerWrapperMarginMobile {
  margin-top: 20px !important;
}

.headerTextDivider {
  width: 450px;
  height: 0.35rem;
  margin-top: -20px;
  background-color: $secondary;
}

.videoSection {
  background-size: cover;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: none;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  box-shadow: 0px 20px 200px 50px rgba(0, 0, 0, 0.3);
  border-radius: 40px;
  overflow: hidden;
  background-color: white;
}

.videoWrapper {
  position: relative;
  padding-top: 56.25%; // 16:9 Aspect Ratio (divide 9 by 16 = 0.5625)
  width: 100%;
  height: 100%;
  z-index: 3 !important;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  visibility: visible;
  // width: 100vw;
  // height: 100vh;
  padding: 0;
  margin: 0;
}

.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #49494928;
  z-index: 3;
  opacity: 1;
  border-radius: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.videoPlayerControls {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoControlButton {
  border: 3px solid white;
  border-radius: 30px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 200ms;
  cursor: pointer;
  margin: 0px 10px;
}

.videoControlButton:hover {
  transform: scale(1.1);
}

.videoControlIcon {
  color: white;
}

.landing_mobile_wrapper {
  display: flex;
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  flex: 1 !important;

  img {
    background-repeat: no-repeat;
    background-size: cover;
    height: auto !important;
    width: 100vw !important;
    object-fit: cover !important;
    bottom: 0;
    left: 0;
  }
}

.hero_grabber_section {
  position: relative;
  width: 90%;
  padding: $minPadding;
  user-select: text;
  z-index: 4;

  h2 {
    white-space: nowrap;
    color: #fff;
    margin: 0;
  }

  p {
    width: 60%;
    font-size: 28px;
    font-weight: 500;
    color: #fff;
  }
}

.home_hero_scroll_text {
  position: absolute;
  bottom: 2.8rem;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .12rem;
  font-size: 16px;
  font-weight: 700;
  animation: scrolldown 3s infinite;
  animation-duration: 3s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: scrolldown;
  opacity: 0;
  user-select: text;
  z-index: 5 !important;
}

@keyframes scrolldown {
  0% {
    transform: translate(-50%, 0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translate(-50%, 20px);
    opacity: 0;
  }
}

.video_flex_wrapper {
  z-index: 3 !important;
  width: 100%;
  height: 100%;
  background-color: $charcoal;
}

.logo {
  z-index: 1;
}


@media screen and (max-width: $tablet) {
  .wrapper {
    height: 70vh !important;
  }

}

@media screen and (max-width: 1325px) {
  .wrapper {
    height: 65vh !important;
  }
}

@media screen and (max-width: 1100px) {
  .wrapper {
    height: 60vh !important;
  }

  .landing_call_to_action {
    border-radius: 8px !important;
  }
}

@media screen and (max-width: $smallTablet) {
  .headerWrapper {
    h1 {
      font-size: 40px !important;
    }

    h3 {
      font-size: 20px;
    }
  }

  .headerTextDivider {
    width: 300px;
    height: 0.35rem;
    margin-top: -10px;
  }

  .hero_grabber_section {
    width: 100%;

    p {
      width: 70%;
    }
  }

  .videoSection {
    scale: 0.7;
    margin-top: 50px;
    height: 90vw;
  }

  .wrapper {
    display: none;
  }

  .landing_mobile_wrapper {
    height: 100%;

    img {
      height: 100% !important;
    }
  }
}

// border: 1px solid var(--text-color-level3,rgba(26,26,26,0.1));
//     border-radius: 8px;
//     box-shadow: 0 1px 8px 0 rgb(0 0 0 / 8%);

@media screen and (max-width: $mobileDisplay) {
  .headerWrapper {
    padding: 0px 0px;
    padding-top: 50px;

    h1 {
      font-size: 30px !important;
    }

    h3 {
      font-size: 20px;
      padding: 0px 10px;
      text-align: center;
    }
  }

  .headerTextDivider {
    width: 300px;
    height: 0.35rem;
    margin-top: -10px;
  }

  .hero_grabber_section {
    p {
      line-height: 1.5 !important;
      width: 90%;
    }
  }

  .landing_call_to_action {
    border-radius: 6px !important;
  }
}

@media screen and (max-width: $smallMobileDisplay) {
  .hero_grabber_section {
    padding: $mobilePadding;

    p {
      width: 100%;
    }
  }

  .wrapper {
    display: none;
  }

  .landing_mobile_wrapper {
    height: 100%;

    img {
      height: 100% !important;

    }
  }
}

@media screen and (max-width: 550px) {
  .wrapper {
    height: 33vh !important;
  }

  .hero_grabber_section {
    p {
      font-size: 12px !important;
    }
  }

  .home_hero_scroll_text {
    font-size: 14px !important;
  }
}

@media screen and (max-width: $tinyMobile) {
  .hero_grabber_section {
    h2 {
      font-size: 16px !important;
    }
  }

  .home_hero_scroll_text {
    font-size: 10px !important;
    bottom: 2rem !important;
  }
}

@media screen and (max-width: 374px) {
  .wrapper {
    height: 23vh !important;
  }

  .hero_grabber_section {
    p {
      font-size: 10px !important;
    }

    h2 {
      font-size: 16px !important;
    }
  }
}