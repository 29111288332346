
.inputBox {
    box-sizing: border-box;
    align-self: center;
    border-radius: 10px;
    caret-color: rgb(130, 130, 130);
    font-weight: bold !important;
    transition: all .15s ease-out;
    // height: 40px;
    line-height: 22px;
    font-size: 30px;
    border: 2px dashed rgb(78, 168, 220);
    padding: 9px 10px;
    outline: none;
    text-align: center;
}

.inputLabel {
    color: white;
    margin-bottom: 10px;
}

.inputBox::placeholder {
    color: #bcbcbc !important;
}

.lockScreen { 
  z-index: 99999;
  width: 100vw; 
  height: 100vh; 
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255,255,255, 0.95) !important;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
 }