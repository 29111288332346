@import "../../Constants/Dimensions.scss";

.card {
    position: relative;
    padding: 1rem;
    height: 600px;
    max-width: 375px;
    width: 375px;
    box-sizing: border-box;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
}

.card:hover {
    cursor: pointer;
    transform: scale(1.02);
}

.cardContentContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    pointer-events: none;
}

.cardContent {
    pointer-events: auto;
    position: relative;
    border-radius: 20px;
    background: #0D3F75;
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.cardImageContainer {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 700px;
}

.cardImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.cardTitleContainer {
    position: absolute;
    top: 0;
    left: 20px;
    max-width: 300px;
}

.cardTitle {
    color: #fff;
    user-select: none;
}

.description {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
}

.controlPointWrapper {
    position: absolute;
    bottom: 15px;
    right: 15px;
    background-color: rgba(89, 84, 82, 0.8);
    padding: 2px;
    border-radius: 50%;
    opacity: 0.8;
    transition: all 0.3s ease-in-out;
}

.controlPointWrapper:hover {
    opacity: 1;
}

@media screen and (max-width: $mobileDisplay) {
    .card {
        // padding: 0.75rem !important;
        height: 450px;
        max-width: 375px;
        width: 375px;

    }

    .controlPointWrapper {
        bottom: 10px;
        right: 10px;
        padding: 1px;
    }
}