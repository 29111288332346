@import "../../Constants/Dimensions.scss";
@import "../../Constants/Colors.scss";

.banner_wrapper {
    width: 100%;
    background: $secondary;
    padding: $regPadding;
    user-select: text !important;
}

.certification_logo {
    cursor: default !important;
}

@media only screen and (max-width: $tablet) {
    .banner_wrapper {
        justify-content: space-between !important;
    }
}

@media only screen and (max-width: $smallTablet) {
    .banner_wrapper {
        padding: $minPadding;
    }
}

@media only screen and (max-width: 725px) {
    .banner_wrapper {
        display: none !important;
    }
}

@media only screen and (max-width: $tinyMobile) {
    .banner_wrapper {
        flex-direction: column !important;
        align-items: center;
        display: flex !important;
        .certification_logo {
            margin-bottom: 20px;
        }
    }
}