@import "../../../Constants/Colors.scss";
@import "../../../Constants/Dimensions.scss";

.hero_wrapper {
    position: relative;
    flex: 1;
    margin: 10px 0 30px 0;
    overflow: hidden;
}

.particle_container {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
}

.waveFill {
    width: 100%;
    background-color: #DFF4FA;
}

.box {
    position: absolute;
    height: 60%;
    top: 25%;
    left: 0;
    background-color: #02334B;
    opacity: .95;
    width: calc(50% - 155px);
    border-top-left-radius: 35%;
    border-top-right-radius: 2%;
    border-bottom-right-radius: 95%;
    border-bottom-left-radius: 18%;
    box-shadow: rgb(0 0 0 / 50%) 0px 0px 30px -5px;
}

@media only screen and (max-width: 1399px) {
    .box {
        display: none !important;
    }
    .hero_content_events {
        color: #02334B;
    }
}

