@import "../../Constants/Dimensions.scss";
@import "../../Constants/Colors.scss";

.wrapper {
    width: 100%;
    min-height: 40vh;
    padding: $minPadding;
    user-select: text;
    margin-bottom: 100px;
    z-index: 2;
}

.hero_image {
    border-radius: 20px;
    overflow: hidden;
}

@media only screen and (max-width: 1400px) {
    .wrapper {
        padding: $minPadding;
    }
}

@media only screen and (max-width: 1100px) {
    .wrapper {
        padding: $tabletPadding !important;
    }
}

@media only screen and (max-width: $smallTablet) {
    .image_wrapper {
        .hero_img {
            width: 50vw;
            height: auto;
        }
    }
}

@media only screen and (max-width: $mobileDisplay) {
    .wrapper {
        flex-direction: column !important;
        margin-bottom: 0 !important;
        padding: 0px 40px !important;
        .image_wrapper {
            display: none;
            .hero_img {
                top: 0 !important;
            }
        }
    }
}