@import "../../../Constants/Dimensions.scss";
@import "../../../Constants/Colors.scss";

.header {
    padding: 20px 15px;
    margin-bottom: 0px;
    position: relative;
    overflow: hidden;
    h2 {
        align-self: center;
        z-index: 2;
        font-size: 38px;
    }
}

.headerHighlight {
  margin: 0;
  padding: 0;
}


.particle {
    height: 5rem;
    width: 5rem;
    border-radius: 5px;
    background-color: #A9CCDA;
    opacity: .4;
    z-index: 1;
    box-shadow: rgb(0 0 0 / 50%) 0px 0px 30px -5px;
}

.circle {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    background-color: #D8D8D8;
    opacity: .3;
    z-index: 1;
}

@media only screen and (max-width: $smallDisplay) {
    .header {
        font-size: 36px !important;
        h2 {
            font-size: 30px;
        }
    }
        .particle {
            height: 5rem;
            width: 5rem;
        }
        .circle {
            height: 5rem;
            width: 5rem;
        }
}

@media only screen and (max-width: 1120px) {
    .header {
        font-size: 28px !important;
    }
        .circle {
            height: 4rem;
            width: 4rem;
        }
}

@media only screen and (max-width: $smallTablet) {
    .header {
        h2 {
        font-size: 24px !important;
        }
    }
}

@media only screen and (max-width: 465px) {
    .header {
        h2 {
            font-size: 18px !important;
            }
    }
}

@media only screen and (max-width: $tinyMobile) {
    .header {
        h2 {
        font-size: 18px !important;
        }
    }
    .circle {
        height: 3rem;
        width: 3rem;
    }
}

@media only screen and (max-width: 375px) {
    .header {
        h2 {
            font-size: 16px !important;
            }
    }
}