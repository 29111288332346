@import "../../../Constants/Dimensions.scss";
@import "../../../Constants/Colors.scss";

.wrapper {
    width: 100%;
    padding: $minPadding !important;
    user-select: text;
    overflow: hidden;
}

@media only screen and (max-width: $smallTablet) {
    .wrapper {
        padding: $mobilePadding !important;
    }
}


@media only screen and (max-width: 400px) {
    .wrapper {
        padding: 10px !important;
    }
}
