@import "../../../Constants/Dimensions.scss";

.wrapper {
  width: 100%;
}

.arrowSection {
  z-index: 1;
  width: 200px;
  opacity: 0.3;
  transition: 200ms;
}

.arrowSection:hover {
  opacity: 1;
}

.leftArrowSection:hover {
  transform: translateX(-5px);
}

.rightArrowSection:hover {
  transform: translateX(5px);
}

.disabledArrowSection {
  opacity: 0.3;
}

.cardCarouselWrapper {
  flex: 1;
  overflow: hidden;
  max-width: 1500px;
  box-sizing: border-box;
  position: relative;
  padding: 0.25rem 0px;
  padding-bottom: 0.5rem;
}

.centerList {
  width: 100%;
  box-sizing: border-box;
}

.outerCard {
  width: 30%;
  flex: 1;
  position: absolute;
  display: none;
  // justify-content: center;
  // align-items: center;
  // opacity: 0;
}

.disabledArrow {
  opacity: 0 !important;
}

.disabledArrow {
  transform: none !important;
}

.buttonWrapper {
  width: 100%;
  margin-bottom: 1rem;
}

.arrow {
  margin-top: 1rem;
  border-radius: 50%;
  background-color: rgba(224, 224, 227, 0.5);
  padding: 0.5rem;
  cursor: pointer;
  transition: ease 200ms;
}

.arrowDisabled {
  // background-color: rgb(243, 243, 244);
  background-color: rgba(224, 224, 227, 0.5);
  cursor: default;
}

.arrow:first-child {
  margin-right: 1rem;
}


@media screen and (max-width: $smallMobileDisplay) {
  .arrowSection {
    width: 75px;
  }
}