@import "../../../Constants/Colors.scss";
@import "../../../Constants/Dimensions.scss";

.wrapper {
    margin: 10px 10px 0 10px;
    width: 35vw;
    min-height: 28rem;
}
// export { default as AboutHero } from './team_photo_tall.jpg';
.content {
    box-sizing: border-box;
    h3 {
        margin: 5px 10px;
        color: $wave;
        font-size: 28px;
    }
    span {
        margin: 10px 5px 10px 10px;
        font-weight: 600;
    }
    .description {
        margin: 0;
        padding: 0;

        p {
            padding: 0;
            margin: 0;
        }
    }
    .address {
        color: $gray;
        font-weight: 600;
    }
    .datetime {
        color: $gray;
        font-weight: 600;
    }
}

.registerButton {
    color: $orange;
    font-size: 1rem !important;
}

.buttonIcon {
    color: $orange;
}


@media only screen and (max-width: $smallDisplay) {
    .wrapper {
        width: 95vw !important;
        justify-content: flex-start !important;
        min-height: 20rem !important;
    }
}

@media only screen and (max-width: 1024px) {
    .event_cell_icon {
        display: none !important; 
    }
}

@media only screen and (max-width: 425px) {
    .address {
        font-size: 0.9rem
    }
    .datetime {
        font-size: 0.9rem !important;
    }

}

@media only screen and (max-width: 385px) {
    .content {
        h3 {
            font-size: 22px;
        }
    }
}

@media only screen and (max-width: 340px) {
    .address {
        font-size: 0.8rem !important;
    }
    .datetime {
        font-size: 0.8rem !important;
    }

}