@import "../../../Constants/Dimensions.scss";
@import "../../../Constants/Colors.scss";

.wrapper {
    h2 {
        font-size: 36px;
    }
}

.content {
    width: 90% !important;
    font-size: 20px;
    line-height: 1.8;
    margin-top: 0 !important;
}

@media only screen and (max-width: $smallDisplay) {
    .content {
        font-size: 20px !important;
        line-height: 1.5;
    }
}

@media only screen and (max-width: 1220px) {
    .content {
        font-size: 18px !important;
    }
}

@media screen and (max-width: $mobileDisplay) {
    .wrapper {
        justify-content: flex-start !important;
        width: 100vw !important;
        padding: 30px 40px;
    }
}

@media screen and (max-width: $tinyMobile) {
    .content {
        font-size: 14px !important;
    }
}