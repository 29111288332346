@import "../../../Constants/Dimensions.scss";
@import "../../../Constants/Colors.scss";

.wrapper {
    width: 100%;
    min-height: 25vh;
    padding: $minPadding;
    background: $secondary;
    z-index: -1;
    user-select: text;
    p {
        font-size: 20px !important;
        line-height: 1.4 !important;
    }
}

.content_section {
    font-size: 20px !important;
    line-height: 1.4 !important;
}

.image_section {
    flex: 1 !important;
}

.imageWrapper {
    // border-radius: 50%;
    padding: 50px;
    z-index: -1;
}

.vision_content_wrapper {
    flex: 1;
    color: #fff;
    box-sizing: border-box;
    padding: 0 10px;
}

.particle:nth-child(1) {
    height: 6rem;
    width: 6rem;
    background: #d0d0d0;
    position: absolute;
    opacity: .5;
    right: 1.25rem;
    bottom: 5.5625rem;
    z-index: -1;
    // border-radius: 50%;
}

.particle:nth-child(2) {
    height: 3.5rem;
    width: 3.5rem;
    background: $turquoiseBlue;
    opacity: .15;
    position: absolute;
    bottom: 4.25rem;
    right: 6.0625rem;
    z-index: -1;
    // border-radius: 50%;
}

.particle:nth-child(3) {
    height: 4rem;
    width: 4rem;
    background: $turquoiseBlue;
    position: absolute;
    opacity: .2;
    left: 15.6875rem;
    top: 6.8125rem;
    z-index: -1;
    // border-radius: 50%;
}

@media only screen and (max-width: $smallDisplay) {
    .wrapper {
        padding: $minPadding;
        h2 {
            font-size: 30px;
        }
    }
}

@media only screen and (max-width: 1226px) {
    .wrapper {
        padding: $mobilePadding;
        flex-direction: row-reverse !important;
        .header {
            padding-top: 0 !important;
        }
        .imageWrapper {
            padding: 5px !important;
        }
    }
}


@media only screen and (max-width: $mobileDisplay) {
    .wrapper {
        .header {
            padding-top: 20px !important;
        }
    }
    .particle {
        display: none !important;
    }
    .content_section {
        padding-bottom: 10px;
    }
    .image_section {
        display: none !important;
    }
}

@media only screen and (max-width: 730px) {
    .wrapper {
        flex-direction: column-reverse !important;
        .header {
            padding-top: 30px !important;
        }
    }

    .imageWrapper {
        display: none !important;
    }
    .content_section {
        padding-bottom: 0 !important;
    }
}