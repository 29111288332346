@import "../../Constants/Colors.scss";

.wrapper {
    position: relative;
    flex: 1;
    background-color: $offWhite;
}

@media only screen and (max-width: 1399px) {
    .events_grabber {
        color: #02334B !important;
    }
}

