@import "../../Constants/Colors.scss";
@import "../../Constants/Dimensions.scss";

.wrapper {
  position: relative;
  user-select: text;
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -40px;
  z-index: 0;
  left: 0;
  background: rgb(255, 255, 255);
}

.wave {
  position: relative;
  top: 40;
  right: 0px;
}

.foreground {
  position: relative;
  top: 0;
  z-index: 10;
  left: 0;
  right: 0;
  width: 100%;
  overflow: auto;
  height: 100%;
  background: rgba(255, 65, 65, 0);
}

.media_team_img {
  display: none;
}

.image_wrapper {
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  flex: 1 !important;
  -webkit-box-shadow: 0 16px 20px rgb(0 0 0 / 10%) !important;
  box-shadow: 0 16px 20px rgb(0 0 0 / 10%) !important;

  .hero_img {
    height: auto !important;
    width: 100% !important;
    background-size: cover !important;
    object-fit: cover !important;
    background-repeat: no-repeat !important;
  }
}

@media only screen and (max-width: $mobileDisplay) {
  .wave {
    display: none !important;
  }

  .image_wrapper {
    display: none !important;
  }

  .mobile_team_img {
    display: flex;
    height: auto !important;
    // background-image: url('../../../src/Assets/Images/team_photo.jpg');   
    // background-position: 50% -80px;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover !important;
    padding-top: 17rem;
  }
}

// @media only screen and (max-width: 635px) {
//   .mobile_team_img {
//     // padding-top: 11rem;
//     // background-position: 60% -40px;
//   }
// }

@media only screen and (max-width: 500px) {
  .mobile_team_img {
    padding-top: 10rem;
  }
}

@media only screen and (max-width: 320px) {
  .mobile_team_img {
    padding-top: 8rem;
  }
}