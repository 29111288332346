@import "../../../Constants/Dimensions.scss";
@import "../../../Constants/Colors.scss";

.wrapper {
  padding: $regPadding;
}

.exhibitListWrapper {
  flex: 1;
  padding-right: 40px;
  position: relative;
}

.pieChartFlexWrapper {
  flex: 1.4;
}

.pieChartWrapper {
  padding: 20px 30px;
  border-radius: 20px;
}

.exhibitInfoWrapper {
  box-sizing: border-box;
  padding: 10px 40px;
  border-radius: 25px;
  transition: 100ms;
  transform: scale(0.9);
  transform-origin: left;
}

.exhibitInfoWrapper:hover {
  background-color: lighten($primary, 55%);
}

.exhibitTitle {
  margin-bottom: 0px;
}

.exhibitContent {
  line-height: 1.3;
}

.exhibitInfoWrapperActive {
  opacity: 1;
  transform: scale(1.0);
  color: white !important;
  // background-color: $primary !important;
  // background: linear-gradient(to right bottom,lighten($primary, 10%), $primary) !important;
  background: linear-gradient(to right bottom, lighten($primary, 10%), $primary) !important;
  box-shadow: -10px 10px 100px -10px rgba(0, 0, 0, 0.397);
  // box-shadow: -10px 10px 60px -10px lighten($secondary, 10%);
  z-index: 2;
}

.pieChart {
  margin: 1em 0px;
}

@media screen and (max-width: $smallDisplay) {
  .exhibitListWrapper {
    padding-right: 20px;
  }
  
  .pieChartFlexWrapper {
    flex: 0.8;
  }
  
  .pieChartWrapper {
    padding: 20px 10px;
    border-radius: 10px;
  }

  .wrapper {
    padding: $minPadding;
  }
}

@media screen and (max-width: $tablet) {
  .exhibitListWrapper {
    padding-right: 10px;
  }
  
  .pieChartFlexWrapper {
    flex: 0.5;
    width: 100%;
  }
  
  .pieChartWrapper {
    padding: 20px 5px;
  }

  // .wrapper {
  //   padding: $mobilePadding;
  // }
}

@media screen and (max-width: $smallTablet) {
  .pieChartFlexWrapper {
    display: none !important;
  }
}