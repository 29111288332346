@import "../../../Constants/Dimensions.scss";
@import "../../../Constants/Colors.scss";

.wrapper {
    width: 100%;
    user-select: text;
    overflow: hidden;
    padding: 50px 0;
}

.item_wrapper {
    height: 350px;
    max-height: 350px;
    .mainDivider {
        min-height: 5px;
        // max-height: 5px;
        width: 40%;
        margin: 5px 0px;
        transition: 200ms;
        background: $turquoiseBlue;
        display: flex;
        align-self: center;
    }
    text-align: center;
}

.bioCard {
    .mainDivider {
        height: 5px;
        width: 50%;
        margin: 5px 0px;
        transition: 200ms;
        background: $turquoiseBlue;
        display: flex;
        align-self: flex-start;
    }
}

.more_btn_wrapper{
    // background-color: green;
    width: 100%;
    align-items: center;
    justify-content: center;
    // padding: 1rem 0;
    // margin-top: 1rem;
    bottom: 0;
    position: fixed;
    padding-bottom: 1.5rem;
}

.bioDetails {
    width: 90%;
    background-color: #ffffff;
    padding: 5px;
    text-align: center;
    * {
        white-space: nowrap;
        text-align: center;
    }
}

.item_wrapper:hover {
    .mainDivider {
        width: 70%;
    }
    button {
        // opacity: 1.0;
        .readBioContent > *:nth-child(2) {
        transform: translateX(10px);
        }
    }  
}

.bioCard:hover {
    .mainDivider {
        width: 90%;
    }
    button {
        // opacity: 1.0;
        .readBioContent > *:nth-child(2) {
        transform: translateX(10px);
        }
    }  
}

.readBioButton {
    width: 120px;
    margin-top: 20px;
    color: rgb(34, 141, 168);
}

.item:hover {
        transform: translateY(-3px);
        transition: 200ms;
        // transition: all 400ms ease;
}

.item_wrapper {
    border-bottom-left-radius: .4rem;
    border-bottom-right-radius: .4rem;
    padding: 3rem;
    // padding-top: 6rem;
    box-shadow: 0 6px 10px rgb(216 216 232 / 50%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: rgba(248,248,248,0.85);
    width: 375px;
    margin: 10px 20px;
}

.image_wrapper {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}


.image {
    position: absolute;
    border-radius: 9999px;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
    width: 140px;
    height: 140px;
    box-shadow: 0 1px 1px 0 rgb(216 216 232 / 50%);
    background-color: #fff;
}

// display: flex;
// // background-image: url('../../../src/Assets/Images/team_photo.jpg');
// background-position: 50% -80px;
// background-repeat: no-repeat;
// background-size: cover;
// padding-top: 17rem;