@import "../../../../Constants/Colors.scss";
@import "../../../../Constants/Dimensions.scss";

.faqBox {
    position: relative;
    z-index: 1;
    color: #fff;
    background-color: #1364BA;
    padding: 1rem 3rem;
    width: 30%;
    margin: 0 25px;
    flex: 0.7;
    h3 {
        padding: 0;
        margin: 0;
        font-size: 32px;
    }
    p {
        font-size: 24px;
    }
}

.buttonClass {
    color: #fff;
}

.buttonIcon {
    color: #fff;
}

@media only screen and (max-width: 1480px) { 
    .buttonClass {
        font-size: 16px !important;
    }
}

@media only screen and (max-width: 1250px) { 
    .faqBox {
        width: 50%;
        padding: 1rem 1.5rem;
        h3 {
            font-size: 32px;
        }
        p {
            font-size: 28px;
        }
    }

}

@media only screen and (max-width: 1125px) { 
    .faqBox {
        flex: 0.9;
        p {
            margin-bottom: 70px;
        }
    }
}

@media only screen and (max-width: $smallTablet) { 
        .faqBox {
            width: 95%;
            padding: 1rem 2rem;
            margin: 0 15px;
            p {
                font-size: 24px !important;
                line-height: 1.5;
                margin-bottom: 50px;
            }
            .buttonClass {
                font-weight: 600;
            }
        }
}

@media only screen and (max-width: $smallMobileDisplay) {
    .faqBox {
        p {
            margin-bottom: 20px;
        }
    }
}

@media only screen and (max-width: 500px) {
    .faqBox {
        p {
            font-size: 20px !important;
        }
    }
}

@media only screen and (max-width: $tinyMobile) {
    .faqBox {
        h3 {
            font-size: 24px;
        }
        p {
            font-size: 18px !important;
        }
        .buttonClass {
            font-size: 14px !important;
        }
    }
}

@media only screen and (max-width: 375px) {
    .faqBox {
        h3 {
            font-size: 20px;
        }
        p {
            font-size: 14px !important;
        }
        .buttonClass {
            font-size: 11px !important;
        }
    }
}