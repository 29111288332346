@import "../../Constants/Dimensions.scss";
@import "../../Constants/Colors.scss";


.wrapper {
    position: relative;
    width: 100%;
    padding-top: 0;
    margin-top: 0;
    overflow: hidden;
}

// .waveContainer {
//     padding: $regPadding;
// }

.contentReverse {
    flex-direction: row-reverse !important;
    div:nth-child(1) {
      justify-content: flex-end;
    }
  }

.background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 0;
    left: 0;
    background: rgb(255, 255, 255);
}

.foreground {
    position: relative;
    z-index: 10;
    width: 100vw;
    height: 100%;
    background: rgba(255, 65, 65, 0);
    color: #fff !important;
}

// @media screen and (max-width: $smallDisplay) {
//     .waveContainer {
//         padding: $minPadding;
//     }
// }

// @media screen and (max-width: $smallMobileDisplay) {
//     .waveContainer {
//       padding: $mobilePadding;
//     }
  
//   }