@import "../../../Constants/Dimensions.scss";
@import "../../../Constants/Colors.scss";

.wrapper {
    width: 100%;
    padding: $regPadding;
    user-select: text;
    overflow: auto;
}

@media only screen and (max-width: $smallTablet) {
    .wrapper {
        padding: $minPadding;
    }
}

@media only screen and (max-width: $smallMobileDisplay) {
    .wrapper {
        padding: $mobilePadding;
    }
}