@import "../../Constants/Dimensions.scss";
@import "../../Constants/Colors.scss";
@import "../../Constants/DefaultFonts.scss";

.wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: $regPadding;
  border-top: 2px solid rgb(241, 241, 241);
  background: $offWhite;
  user-select: text;
}

.tagline {
  color: #000;
  font-family: $brand;
  font-style: italic;
  margin: 5px 0;
  font-size: 16px;
}

.navGroup {
  padding: 10px 30px;
  .mainButton {
    cursor: pointer;
    margin-bottom: 5px;
    color: $violet;
    transition: 200ms;
    .mainDivider {
      height: 5px;
      width: 50%;
      margin: 5px 0px;
      transition: 200ms;
      background: $turquoiseBlue;
    }
  }

  .mainButton:hover {
    .mainDivider {
      width: 100%;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    li {
      margin: 0;
      padding: 5px 0px;
      button {
        cursor: pointer;
        transition: 200ms;
        white-space: nowrap;
        font-size: 15px;
        border-bottom: 4px solid rgba(0, 0, 0, 0);
        padding-bottom: 5px;
        box-sizing: border-box;
        width: "100%";
      }
      button:hover {
        transform: translateY(-2px);
        border-bottom: 4px solid #228da8;
      }
    }
  }
}

.location_wrapper {
  padding: 10px 0;
  box-sizing: border-box;
  user-select: text !important;
  margin-left: 25px;
  span {
    font-size: 15px;
    margin: 10px 0;
    box-sizing: border-box;
  }
}

.location_wrapper_mobile {
  display: none !important;
}

.buttonSection {
  margin-left: 60px;
}

.disclosure_wrapper {
  margin-bottom: 50px;
  p {
    line-height: 1.2;
    font-size: 13px;
  }
  h5 {
    font-size: 13px;
    padding: 0;
    margin: 0;
  }
  .summary_pdf {
    text-decoration: underline;
    font-size: 12px;
    margin-left: 5px;
  }
}

@media screen and (max-width: 1520px) {
  .location_wrapper {
    margin-left: 5px !important;
  }
}

@media screen and (max-width: $smallDisplay) {
  .buttonSection {
    display: none !important;
  }
}

@media screen and (max-width: $smallTablet) {
  .navGroupWrapper {
    display: none !important;
  }
  .location_wrapper_mobile {
    display: flex !important;
    box-sizing: border-box !important;
    span {
      font-size: 15px !important;
      margin: 20px 0;
      box-sizing: border-box;
      text-align: center;
    }
  }
}

@media screen and (max-width: 700px) {
  .wrapper {
    padding: $mobilePadding;
  }
}

@media screen and (max-width: 550px) {
  .navGroupWrapper {
    display: none !important;
  }
  .location_wrapper_mobile {
    span {
      font-size: 15px !important;
      margin: 40px !important;
    }
  }
}
