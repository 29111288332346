@import "../../Constants/Dimensions.scss";

.learnMoreContent > *:nth-child(2) {
  transition: 200ms;
}

.learnMoreButton {
  cursor: pointer;
  width: fit-content;
  margin-top: 40px;
  color: rgb(34, 141, 168);
}

.hovered {
  .learnMoreContent > *:nth-child(2) {
    transform: translateX(10px);
  }
}

@media screen and (max-width: $smallDisplay) {
  .learnMoreButton {
    margin-top: 25px;
  }

  .learnMoreContent {
    span {
      font-size: 15px;
    }
  }
}

@media screen and (max-width: $tablet) {

  .learnMoreButton {
    margin-top: 10px;
  }

  .learnMoreContent {
    span {
      font-size: 15px;
    }
  }
}

