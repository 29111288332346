@import "../../Constants/Dimensions.scss";
@import "../../Constants/Colors.scss";

.container {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100vw;
    margin-top: 32px;

    .videoHeader {
        text-align: center;
        margin: 0;
    }
}

.videoSection {
    display: flex;
    position: relative;
    padding-top: 56.25%;
    width: 100%;
    box-shadow: 0px 20px 200px 50px rgba(0, 0, 0, 0.3);
    border-radius: 40px;
    overflow: hidden;
    background-color: white;
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    // width: 100vw;
    // height: 100vh;
}

.videoOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: #49494928;
    z-index: 3;
    opacity: 1;
    border-radius: 40px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border: 1px solid #fff;
}

.videoPlayerControls {
    width: 100%;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}

.videoControlButton {
    border: 3px solid #018FC4;
    border-radius: 30px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 200ms;
    cursor: pointer;
    margin: 0px 10px;
}

.videoControlButton:hover {
    transform: scale(1.1);
}

.videoControlIcon {
    color: #018FC4;
}

@media only screen and (max-width: 900px) {
    .videoControlButton {
        height: 30px !important;
        width: 30px !important;
        border: 2px solid #018FC4;
    }

    .videoControlIcon {
        height: 16px !important;
        width: 16px !important;
    }

    .videoPlayerControls {
        height: 60px;
    }
}

@media only screen and (max-width: 600px) {
    .videoControlButton {
        height: 20px !important;
        width: 20px !important;
    }

    .videoPlayerControls {
        height: 40px;
    }
}