@import "../../../Constants/Colors.scss";
@import "../../../Constants/Dimensions.scss";

.section {
    padding: $minPadding;
    width: 100%;
    background-color: #F0F3F7;
    padding-bottom: 70px;
    overflow: hidden;
    .header {
        width: 100%;
        padding: 15px 0;
        text-align: center;
    }
    h2 {
        display: flex;
        justify-content: center !important;
        padding-bottom: 0;
        margin-bottom: 0;
        width: 100%;
        font-size: 32px;
        font-weight: 500;
        color: $violet;
    }
    .accent {
        height: 4px;
        margin: 20px 0 40px 0;
        width: 55px;
        background-color: #3132D5;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
    }
}

.card:first-child {
border-top-left-radius: 6px;
border-bottom-left-radius: 6px;
border-right: 1px solid #E6ECF8;
}

.card:nth-child(2n) {
border-right: 1px solid #E6ECF8;
}

.card:nth-child(3n) {
border-top-right-radius: 6px;
border-bottom-right-radius: 6px !important;
}

@media only screen and (max-width: 1200px) {
    .card {
        border: none !important;
        border-radius: 0 !important;
        box-shadow: rgba(13, 13, 13, 0.1) 0px 0.25rem 0.375rem 0px;
        margin: 10px;
    }
    .card_wrapper {
        flex-direction: column !important;
        position: relative;
        bottom: 0;
        margin-bottom: 50px;
        flex-wrap: wrap !important;
        justify-content: center;
        border: none !important;
        box-shadow: none !important;
    }
}

@media only screen and (max-width: 700px) {
    .card {
        margin: 5px;
    }
    .section {
        h2 {
            font-size: 24px;
        }
    }
}

@media only screen and (max-width: 525px) {
    .section {
        padding: $mobilePadding;
        h2 {
            font-size: 20px;
        }
    }
}