@import "../../../Constants/Dimensions.scss";

.container {
    position: relative;
    background-color: white;

    .imageWrapper {
        height: 275px;
        width: 100%;
        background-color: #0D3F75;

        .image {
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            // background: #1c1c1e;

            .title {
                color: #fff;
                padding-left: 1rem;
                max-width: 400px;
            }
        }
    }

    .textContainer {
        padding: 0 1rem;
        align-items: center;
        width: 100%;

        .headline {
            align-self: flex-start;
            text-align: center;
            padding: 0;
            margin-bottom: 0;
        }

        .description {
            font-weight: 500;
        }

        .learnMoreLink {
            color: #000;
            font-weight: 600;
            text-decoration: underline;
            transition: all 0.2s ease-in-out;
        }

        .learnMoreLink:hover {
            color: #2993D1;
        }
    }

}