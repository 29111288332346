@import "../../Constants/Dimensions.scss";
@import "../../Constants/Colors.scss";

.headerHighlight {
    background-color: $secondary1;
    height: 0.35rem;
    width: 2.7rem;
    display: block;
    margin-bottom: 0.75rem;
}

@media only screen and (max-width: 1220px) {
    .headerHighlight {
        height: 0.3rem;
        width: 2.5rem;
        margin-bottom: 0.5rem;
    }
}