@import "../../../Constants/Colors.scss";
@import "../../../Constants/Dimensions.scss";

.wrapper {
  width: 100%;
  background-color: white;
  padding: 60px 0px;
  width: 100vw;
  overflow-x: hidden;
}

.content {
  padding: 20px 0;
  overflow-x: hidden;
}

.carouselMobileContainer {
  display: none;
}

.sectionHeader {
  line-height: 1.075;
  letter-spacing: -0.005em;
}

.carouselDesktopContainer {
  display: flex;
}

@media only screen and (max-width: 430px) {
  .carouselMobileContainer {
    display: flex;
  }

  .carouselDesktopContainer {
    display: none;
  }
}