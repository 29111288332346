@import "../../../Constants/Colors.scss";
@import "../../../Constants/Dimensions.scss";

.card {
    padding: 30px 15px;
    width: 400px;
    max-width: 400px;
    height: 500px;
    background: $offWhite;
    box-shadow: 0 5px 5px 0 rgb(233 240 243 / 50%), 0 0 0 1px #e6ecf8;
    z-index: 9;
    span {
        font-weight: 600;
        font-size: 18px;
        color: $violet;
    }
    p {
        text-align: center;
        width: 85%;
        color: $wave;
        font-weight: 500;
        line-height: 1.5;
    }
}

.title_wrapper {
    justify-content: flex-start !important;
}

.card_image {
    padding: 10px 0;
}

.learn_more {
    flex: 1 !important;
}

.learn_more_button {
    color: $orange !important;
    padding: 10px 25px;
    border: 2px solid $orange;
    border-radius: 10px;
    cursor: pointer;
    transition: 100ms;
}

.learn_more_button:hover {
    background-color: $violet;
    transform: translateY(-2px);
    box-shadow: 0px 2px 20px 5px rgba(0, 0, 0, 0.222);
    border: 2px solid $violet;
    color: white !important;
    transform: translateY(-2px) !important;
}

.icon {
    color: $orange;
}