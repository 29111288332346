@import "../../../../../Constants/Colors.scss";
@import "../../../../../Constants/Dimensions.scss";

.sectionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;
}

.sectionWrapper:last-child {
    border-bottom: 2px solid #C1BEB5;
}

    .sectionHeader {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-size: 24px;
        font-weight: 600;
        color: #000;
        user-select: text;
        padding: 1rem 0;
        box-sizing: border-box;
        cursor: pointer;
        cursor: pointer;
        border-top: 2px solid #C1BEB5;
            label {
            text-align: left;
            width: 100%;
            cursor: pointer;
            }
    }

.addIcon {
    cursor: pointer;
    margin-right: 0.5rem;
    color: #000;
    box-sizing: border-box;
}

@media only screen and (max-width: $smallDisplay) { 
    .sectionWrapper {
        .sectionHeader {
            padding: 0.7rem 0;
            font-size: 18px !important;
        }
    }
}


@media only screen and (max-width: $smallTablet) {
    .sectionWrapper {
        .sectionHeader {
            font-size: 22px !important;
        }
    }
} 

@media only screen and (max-width: $smallMobileDisplay) {
    .sectionWrapper {
        .sectionHeader {
            font-size: 16px !important;
            padding: 0.5rem 0;
        }
    }
}

@media only screen and (max-width: 375px) {
    .sectionWrapper {
        .sectionHeader {
            font-size: 13px !important;
            // padding: 0.5rem 0;
        }
    }
}