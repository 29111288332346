@import "../../../Constants/Colors.scss";
@import "../../../Constants/Dimensions.scss";

.topSection {
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 10px;
}

.dividerBlock {
  width: 30%;
  height: 20px;
  transition: 200ms;
  background-color: lighten($turquoiseBlue, 15%); // rgba(6, 141, 168, 0.068);
}

.cardTitle {
  width: 100%;
}

.learnMoreButton {
  width: 150px;
  margin-top: 40px;
  color: rgb(34, 141, 168);
}

.wrapper {
  // background-color: purple !important;
  flex: 1;
  height: 100% !important;
}

.wrapper:hover {
  .dividerBlock {
    width: 100%;
  }
  button {
    .learnMoreContent > *:nth-child(2) {
      transform: translateX(10px);
    }
  }
}


@media screen and (max-width: $tablet) {
  // .cardTitle {
  //   font-size: 15px;
  // }
  .subText {
    font-size: 14px !important;
  }
}


