@import "../../../Constants/Dimensions.scss";
@import "../../../Constants/Colors.scss";

.container {
    padding: 0 2rem;
}

.name {
    color: #000000;
    margin: 0;
    padding: 5px 0;
}

.phone {
    padding: 5px 0;
    font-weight: 500;
}

.title {
    color: #000;
    font-weight: 600;
    padding: 5px 0;
}

.linkedin {
    font-weight: 500;
}

.bio_details {
    margin-top: 16px;
    flex-direction: column !important;
}

.description {
    color: #000;
    font-weight: 500;
    line-height: 1.3;
}

.image {
    z-index: 2;
    padding-top: 15px;
    user-select: text;
    max-height: 300px;
    max-width: 300px;
    margin-left: 12px;
}

.particle:nth-child(1) {
    height: 3rem;
    width: 3rem;
    background: #d0d0d0;
    position: absolute;
    opacity: .5;
    left: -1.5rem;
    top: -16rem;
    z-index: 1;
}

.particle:nth-child(2) {
    height: 1.3rem;
    width: 1.3rem;
    // background: #add7c7;
    background: $primary;
    opacity: .3;
    position: absolute;
    left: -3rem;
    bottom: 12rem;
    z-index: 1;
}

.particle:nth-child(3) {
    height: 4.5rem;
    width: 4.5rem;
    background: #c3c3c3;
    // background: $turquoiseBlue;
    position: absolute;
    opacity: .4;
    right: -1.6875rem;
    bottom: -1.4125rem;
    z-index: 1;
}

.bioHightlightHeader {
    color: $secondary;
    font-weight: 600;
    font-size: 18px;
}

@media only screen and (max-width: $smallDisplay) {
    .image {
        width: 300px;
    }

    .phone {
        font-size: 16px !important;
    }

    .title {
        font-size: 16px !important;
    }
}

@media only screen and (max-width: 1050px) {
    .imageWrapper {
        align-items: center;
    }

    .image {
        width: 250px;
    }

    .particle:nth-child(1) {
        top: -13rem;
        width: 2.5rem;
        height: 2.5rem;
    }

    .particle:nth-child(2) {
        bottom: 12rem;
        left: -2rem;
    }

    .particle:nth-child(3) {
        width: 3.5rem;
        height: 3.5rem;
    }
}

@media only screen and (max-width: $mobileDisplay) {
    .contact_info_wrapper {
        .title {
            font-size: 16px;
        }
    }

    .container {
        position: relative;
        width: 100%;
        padding: 0 15px !important;
    }
}

@media only screen and (max-width: $smallMobileDisplay) {
    .particle {
        display: none !important;
    }
}

@media only screen and (max-width: 550px) {
    .contact_info_wrapper {
        flex-direction: column-reverse !important;
    }

    .image {
        position: relative;
        width: 90%;
        padding-right: 15px;
        padding-left: 15px;
        border-radius: 50%;
        margin: 0;
    }
}