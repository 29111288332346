@import "../../Constants/Colors.scss";


.gradient-box {
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(45deg,  $orange, #2A93D1, #F99060, #1AA3CE, #80ffe8);
    background-size: 600% 100%;
    animation: gradient 10s ease-in infinite;
    animation-direction: alternate;
}

@keyframes gradient {
    0% {background-position: 0%}
    100% {background-position: 100%}
}
