@import "../../../Constants/Dimensions.scss";
@import "../../../Constants/Colors.scss";


.wrapper {
  width: 100%;
}

.firstBox {
  background: rgb(238, 238, 238);
  height: 50vh;
  width: 50vh;
  border-radius: 10px;
}


.secondBox {
  background: rgb(59, 59, 59);
  position: relative;
  left: -15px;
  top: -15px;
  height: 50vh;
  width: 50vh;
  border-radius: 10px;
  box-shadow: 0px 2px 30px 1px rgba(0, 0, 0, 0.322);
}

.check_icon {
  box-shadow: 0px 2px 30px 1px rgba(0, 0, 0, 0.322) !important;
  border-style: dotted !important;
}