@import "../../../Constants/Colors.scss";
@import "../../../Constants/Dimensions.scss";

.wrapper {
  transition: 400ms;
  box-sizing: border-box;
  margin: 0px 40px;
  max-width: 430px;
  border-radius: 10px;
  cursor: pointer;
  padding: 30px;
}

.lightTheme {
  background-color: rgb(255, 255, 255);
  border: 5px solid rgba(34, 141, 168, 0.212);
}

.wrapper:hover {
  box-shadow: 0px 15px 50px 1px rgba(0, 0, 0, 0.158);
  transform: translateY(-5px);
  background-color: white;
  border: 5px solid rgb(34, 141, 168);
}

@media screen and (max-width: $smallDisplay) {
  .wrapper {
    padding: 20px;
    margin: 0px 20px;
  }
}

@media screen and (max-width: $tablet) {
  .wrapper {
    padding: 10px;
    margin: 0px 10px;
    border: 3px solid rgba(34, 141, 168, 0.212);
  }
}

@media screen and (max-width: $tinyMobile) {
  .wrapper {
    padding: 10px;
    margin: 0px 1px;
  }
}
