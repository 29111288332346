@import "../../../../Constants/Colors.scss";
@import "../../../../Constants/Dimensions.scss";

.wrapper {
    width: 100%;
}

.section {
    color: #fff;
    text-align: center;
}

.content {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.03em;
}

@media only screen and (max-width: $smallDisplay) {
    .parallax_wrapper {
    height: 65vh !important;
    }
    .banner_content {
        width: 100% !important;
    }
}

@media only screen and (max-width: 1250px) {
    .parallax_wrapper {
    height: 45vh !important;
    }
}

@media only screen and (max-width: 970px) {
.parallax_wrapper {
    height: 35vh !important;
    }
    .retirement_content {
        flex-direction: column !important;
    }
    .bullet_points {
        width: 80%;
    }
    .section {
        h2 {
            font-size: 24px;
        }
    }
    .content {
        font-size: 20px;
    }
}

@media only screen and (max-width: 850px) {
    .content {
        display: none;
    }
    .parallax_wrapper {
        height: 30vh !important;
    }
}

@media only screen and (max-width: 435px) {
    .parallax_wrapper {
    height: 25vh !important;
    }
}

@media only screen and (max-width: 400px) {
    .section {
        h2 {
            font-size: 20px;
        }
    }
}

@media only screen and (max-width: 375px) {
    .parallax_wrapper {
    height: 20vh !important;
    }
}